import { Locale } from "../schema/Locale.js";

const dateTimeMonthYearFormat: Record<Locale, Intl.DateTimeFormat> = {
	[Locale.cs]: new Intl.DateTimeFormat("cs-CZ", {
		year: "numeric",
		hour: "numeric",
		minute: "2-digit",
		day: "numeric",
		month: "short",
		timeZone: "Europe/Prague",
	}),
};

export function toDateTimeMonthYearFormat(date: number | Date | string, locale: Locale): string {
	return dateTimeMonthYearFormat[locale].format(typeof date === "string" ? new Date(date) : date);
}
